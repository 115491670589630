import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import { useIntl } from 'gatsby-plugin-intl'

//COMPONENTS
import Header from '../components/header'
import Footer from '../components/footer'
import ContactInfo from '../components/contactInfo'
/* import ContactCard from '../components/contactCard' */

//STYLES
import '../styles/contact.css'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'

const Contact = ({ data }) => {
  const intl = useIntl()
  const md = data.allMarkdownRemark.edges[0].node.frontmatter
  let jsonEn = data.allIntlJson.edges[0]
  let jsonSv = data.allIntlJson.edges[1]
  const { t } = useTranslation()
  let contactInfoArraySv = jsonSv.node.contact.contactArray.map((item, i) => (
    <ContactInfo
      key={i}
      type={t(`contactTitle.${i}`, { returnObjects: true })}
      text={t(`contactText.${i}`, { returnObjects: true })}
      icon={t(`contactIcon.${i}`, { returnObjects: true })}
      number={item.number}
      mail={item.mail}
    />
  ))

  let contactInfoArrayEn = jsonEn.node.contact.contactArray.map((item, i) => (
    <ContactInfo
      key={i}
      type={t(`contactTitle.${i}`, { returnObjects: true })}
      text={t(`contactText.${i}`, { returnObjects: true })}
      icon={t(`contactIcon.${i}`, { returnObjects: true })}
      number={item.number}
      mail={item.mail}
    />
  ))

  return (
    <div id="contact">
      <BackgroundImage
        className="header-img"
        fluid={data.contactImage.childImageSharp.fluid}
        alt="Contact header image"
      >
        <Header />
        <div className="selling-text">
          <h1>{intl.formatMessage({ id: 'contact.title' })}</h1>
        </div>
      </BackgroundImage>
      <main>
        <div className="contact-us">
          <p className="blue-text">
            {intl.formatMessage({ id: 'contact.contactUs' })}
          </p>
          <h2 className="section-title">
            {intl.formatMessage({ id: 'contact.help' })}
          </h2>
        </div>
        <div className="contact-section">
          {intl.locale === 'sv' ? contactInfoArraySv : contactInfoArrayEn}
        </div>
        <div className="visit-us">
          <p className="blue-text">
            {intl.formatMessage({ id: 'contact.visitUs' })}
          </p>
          <h2 className="section-title">
            {intl.formatMessage({ id: 'contact.offices' })}
          </h2>
        </div>
        <div className="contact-card-grid">
          <div className="contact-card first">
            <div className="context-card-text">
              <h4 className="office-title">
                {intl.formatMessage({ id: 'contact.office' })}
              </h4>
              <h3 className="office-city">
                {intl.formatMessage({ id: 'contact.orebro' })}
              </h3>
              <p className="office-location">
                {ReactHtmlParser(md.orebroLocation)}
              </p>
            </div>
            <Img
              className="first-img card-img"
              fluid={data.firstImage.childImageSharp.fluid}
            />
          </div>
          <div className="contact-card second">
            <div className="context-card-text second">
              <h4 className="office-title">
                {intl.formatMessage({ id: 'contact.office' })}
              </h4>
              <h3 className="office-city">
                {intl.formatMessage({ id: 'contact.gothenburg' })}
              </h3>
              <p className="office-location">
                {' '}
                {ReactHtmlParser(md.gothenburgLocation)}{' '}
              </p>
            </div>
            <Img
              className="second-img card-img"
              fluid={data.secondImage.childImageSharp.fluid}
            />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export const query = graphql`
  {
    allMarkdownRemark(filter: { frontmatter: { key: { eq: "contact" } } }) {
      edges {
        node {
          id
          frontmatter {
            title
            contactUs
            help
            affair
            affairText
            number
            mail
            visitUs
            office
            orebro
            gothenburg
            orebroLocation
            gothenburgLocation
            contactArray {
              mail
              number
              text
              type
            }
          }
        }
      }
    }
    allIntlJson {
      edges {
        node {
          contact {
            contactArray {
              mail
              number
              text
              type
            }
          }
        }
      }
    }
    contactImage: file(relativePath: { eq: "contact-image.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    firstImage: file(relativePath: { eq: "index-image.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    secondImage: file(relativePath: { eq: "partners-image.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Contact
